@import url(https://fonts.googleapis.com/css?family=Arima+Madurai:300);
#Patrick {
  color: green;
  text-shadow: 1px 1px 2px black, 0 0 25px goldenrod, 0 0 5px gold, -1px 0 black,
    0 1px black, 1px 0 black, 0 -1px black;
  font-family: "Luckiest Guy", cursive;
  /* background-color: rgb(14, 14, 14);
  padding: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: darkgoldenrod;
  box-shadow: 3px 3px 8px 3px goldenrod; */

  border-radius: 30px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}
.NavBar {
  z-index: 2;
  color: green;
  text-shadow: 1px 1px 2px black, 0 0 5px goldenrod, 0 0 5px gold, -1px 0 black,
    0 1px black, 1px 0 black, 0 -1px black;
  font-family: "Luckiest Guy", cursive;
  background-color: grey;
  /* min-height: 10%; */
  text-align: right;
  position: fixed;
  width: 100%;
}
.links {
  right: 0%;
}
.custom-toggler .navbar-toggler {
  border-color: rgb(255, 102, 203);
}
.nav-item {
  margin-left: 10px;
  font-size: larger;
}

.card-footer {
  position: fixed;
  bottom: 0%;
  width: 100%;
  text-align: center;
  background-color: darkslategrey;
  border-top-width: 5px;
  border-color: green;
}
.Footer .container {
  background-image: none;
  background-color: rgb(46, 68, 68);
  background-size: cover;
  padding: 5px;
}

.Footer {
  width: 100%;
}

.Resume {
  z-index: -2;
  margin-bottom: 50px;
}
.resume-btn {
  margin: 20px;
  background-color: rgb(1, 169, 1);
  border-color: black;
  border-radius: 50px;
  padding: 10px;
  margin-bottom: 25px;  
  /* text-shadow: 1px 1px 2px black, 0 0 25px goldenrod, 0 0 5px gold, -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  font-family: "Luckiest Guy", cursive;
}
.resume-header {
  text-align: center;
}
.resume-wordy-bits {
  font-size: smaller;
}
#PDFdiv{
  text-align: center;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5%;
  width: 80%;
  background-color: ivory;
  border-radius: 5px;

}
#resumePDF{
  width: 90%;
  height: 80vh;
}
 h1 {
  font-family: "Luckiest Guy", cursive;
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 5px goldenrod, 0 0 5px gold, -1px 0 black,
    0 1px black, 1px 0 black, 0 -1px black;
}

.About {
  margin: 0;
  min-height: 100vh;
}
.About h1 {
  text-align: center;
  font-family: "Luckiest Guy", cursive;
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 5px goldenrod, 0 0 5px gold, -1px 0 black,
    0 1px black, 1px 0 black, 0 -1px black;
}
#about-image {
  margin: 10px;
}
.About #info-box {
  margin: auto;
  border-radius: 5px;
}
/* #resumeBtn {
  color: antiquewhite;
  margin: 20px;
  background-color: green;
  border-color: black;
  border-radius: 10px;
  padding: 10px;
  width: auto;
} */
.About .container {
  /* margin: auto; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.item {
  display: flexbox;
}

.Home {
  min-height: 100vh;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

#welcomeh4 {
  padding-top: 150px;
  text-align: center;
  margin-bottom: -15%;
  color: white;
  font-family: "Luckiest Guy", cursive;
  text-shadow: 1px 1px 2px black, 0 0 5px goldenrod, 0 0 5px gold, -1px 0 black,
    0 1px black, 1px 0 black, 0 -1px black;
}

.birdroot {
  z-index: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 110vh;
  min-height: 35rem;
  /* background-image: linear-gradient(
        to bottom,
        rgba(255, 168, 76, 0.6) 0%,
        rgba(255, 123, 13, 0.6) 100%
      ),
      url("https://images.unsplash.com/photo-1446824505046-e43605ffb17f"); */
  background-blend-mode: soft-light;
  background-size: cover;
  background-position: center center;
  padding: 2rem;
}
.bird {
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells-new.svg);
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  will-change: background-position;

  -webkit-animation-name: fly-cycle;

          animation-name: fly-cycle;
  -webkit-animation-timing-function: steps(10);
          animation-timing-function: steps(10);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.bird--one {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.bird--two {
  -webkit-animation-duration: 0.9s;
          animation-duration: 0.9s;
  -webkit-animation-delay: -0.75s;
          animation-delay: -0.75s;
}

.bird--three {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
  -webkit-animation-delay: -0.25s;
          animation-delay: -0.25s;
}

.bird--four {
  -webkit-animation-duration: 1.1s;
          animation-duration: 1.1s;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.bird-container {
  position: absolute;
  top: 20%;
  left: -10%;
  -webkit-transform: scale(0) translateX(-10vw);
          transform: scale(0) translateX(-10vw);
  will-change: transform;

  -webkit-animation-name: fly-right-one;

          animation-name: fly-right-one;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.bird-container--one {
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
  -webkit-animation-delay: 0;
          animation-delay: 0;
}

.bird-container--two {
  -webkit-animation-duration: 16s;
          animation-duration: 16s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.bird-container--three {
  -webkit-animation-duration: 14.6s;
          animation-duration: 14.6s;
  -webkit-animation-delay: 9.5s;
          animation-delay: 9.5s;
}

.bird-container--four {
  -webkit-animation-duration: 16s;
          animation-duration: 16s;
  -webkit-animation-delay: 10.25s;
          animation-delay: 10.25s;
}

@-webkit-keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@-webkit-keyframes fly-right-one {
  0% {
    -webkit-transform: scale(0.3) translateX(-10vw);
            transform: scale(0.3) translateX(-10vw);
  }

  10% {
    -webkit-transform: translateY(2vh) translateX(10vw) scale(0.4);
            transform: translateY(2vh) translateX(10vw) scale(0.4);
  }

  20% {
    -webkit-transform: translateY(0vh) translateX(30vw) scale(0.5);
            transform: translateY(0vh) translateX(30vw) scale(0.5);
  }

  30% {
    -webkit-transform: translateY(4vh) translateX(50vw) scale(0.6);
            transform: translateY(4vh) translateX(50vw) scale(0.6);
  }

  40% {
    -webkit-transform: translateY(2vh) translateX(70vw) scale(0.6);
            transform: translateY(2vh) translateX(70vw) scale(0.6);
  }

  50% {
    -webkit-transform: translateY(0vh) translateX(90vw) scale(0.6);
            transform: translateY(0vh) translateX(90vw) scale(0.6);
  }

  60% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.6);
            transform: translateY(0vh) translateX(110vw) scale(0.6);
  }

  100% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.6);
            transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
}

@keyframes fly-right-one {
  0% {
    -webkit-transform: scale(0.3) translateX(-10vw);
            transform: scale(0.3) translateX(-10vw);
  }

  10% {
    -webkit-transform: translateY(2vh) translateX(10vw) scale(0.4);
            transform: translateY(2vh) translateX(10vw) scale(0.4);
  }

  20% {
    -webkit-transform: translateY(0vh) translateX(30vw) scale(0.5);
            transform: translateY(0vh) translateX(30vw) scale(0.5);
  }

  30% {
    -webkit-transform: translateY(4vh) translateX(50vw) scale(0.6);
            transform: translateY(4vh) translateX(50vw) scale(0.6);
  }

  40% {
    -webkit-transform: translateY(2vh) translateX(70vw) scale(0.6);
            transform: translateY(2vh) translateX(70vw) scale(0.6);
  }

  50% {
    -webkit-transform: translateY(0vh) translateX(90vw) scale(0.6);
            transform: translateY(0vh) translateX(90vw) scale(0.6);
  }

  60% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.6);
            transform: translateY(0vh) translateX(110vw) scale(0.6);
  }

  100% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.6);
            transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
}

@-webkit-keyframes fly-right-two {
  0% {
    -webkit-transform: translateY(-2vh) translateX(-10vw) scale(0.5);
            transform: translateY(-2vh) translateX(-10vw) scale(0.5);
  }

  10% {
    -webkit-transform: translateY(0vh) translateX(10vw) scale(0.4);
            transform: translateY(0vh) translateX(10vw) scale(0.4);
  }

  20% {
    -webkit-transform: translateY(-4vh) translateX(30vw) scale(0.6);
            transform: translateY(-4vh) translateX(30vw) scale(0.6);
  }

  30% {
    -webkit-transform: translateY(1vh) translateX(50vw) scale(0.45);
            transform: translateY(1vh) translateX(50vw) scale(0.45);
  }

  40% {
    -webkit-transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
            transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
  }

  50% {
    -webkit-transform: translateY(0vh) translateX(90vw) scale(0.45);
            transform: translateY(0vh) translateX(90vw) scale(0.45);
  }

  51% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.45);
            transform: translateY(0vh) translateX(110vw) scale(0.45);
  }

  100% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.45);
            transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
}

@keyframes fly-right-two {
  0% {
    -webkit-transform: translateY(-2vh) translateX(-10vw) scale(0.5);
            transform: translateY(-2vh) translateX(-10vw) scale(0.5);
  }

  10% {
    -webkit-transform: translateY(0vh) translateX(10vw) scale(0.4);
            transform: translateY(0vh) translateX(10vw) scale(0.4);
  }

  20% {
    -webkit-transform: translateY(-4vh) translateX(30vw) scale(0.6);
            transform: translateY(-4vh) translateX(30vw) scale(0.6);
  }

  30% {
    -webkit-transform: translateY(1vh) translateX(50vw) scale(0.45);
            transform: translateY(1vh) translateX(50vw) scale(0.45);
  }

  40% {
    -webkit-transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
            transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
  }

  50% {
    -webkit-transform: translateY(0vh) translateX(90vw) scale(0.45);
            transform: translateY(0vh) translateX(90vw) scale(0.45);
  }

  51% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.45);
            transform: translateY(0vh) translateX(110vw) scale(0.45);
  }

  100% {
    -webkit-transform: translateY(0vh) translateX(110vw) scale(0.45);
            transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
}

.btn {
  margin: 20px;
  background-color: green;
  border-color: black;
  border-radius: 10px;
  padding: 10px;
  width: 100px;
}
.Contact h3 {
  text-align: center;
  font-family: "Luckiest Guy", cursive;
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 5px goldenrod, 0 0 5px gold, -1px 0 black,
    0 1px black, 1px 0 black, 0 -1px black;
}
#about-links {
  text-align: center;
}
.Contact #info-box {
  margin: auto;
  border-radius: 5px;
}
/* .Contact{
  margin: 0%;
} */
.flip-card {
  background-color: transparent;
  width: 350px;
  height: 350px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: white;
  color: black;
}

.flip-card-back {
  background-color: white;
  color: black;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  overflow: scroll;
}
h6 {
  text-align: left;
}
.text {
  background-color: white;
  width: 350px;
  padding: 5px;
  margin: auto;
  /* margin-top: -8px; */
  margin-bottom: 10px;
  border-radius: 5px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
}

.Portfolio {
  text-align: center;
}
.Portfolio h1 {
  font-family: "Luckiest Guy", cursive;
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 5px goldenrod, 0 0 5px gold, -1px 0 black,
    0 1px black, 1px 0 black, 0 -1px black;
}
.Portfolio #info-box {
  margin: auto;
  border-radius: 5px;
}
#card-info-box {
  margin: auto;
}
.ProjectCard {
  margin: auto;
  justify-content: center;
}

