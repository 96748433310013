.About {
  margin: 0;
  min-height: 100vh;
}
.About h1 {
  text-align: center;
  font-family: "Luckiest Guy", cursive;
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 5px goldenrod, 0 0 5px gold, -1px 0 black,
    0 1px black, 1px 0 black, 0 -1px black;
}
#about-image {
  margin: 10px;
}
.About #info-box {
  margin: auto;
  border-radius: 5px;
}
/* #resumeBtn {
  color: antiquewhite;
  margin: 20px;
  background-color: green;
  border-color: black;
  border-radius: 10px;
  padding: 10px;
  width: auto;
} */
.About .container {
  /* margin: auto; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.item {
  display: flexbox;
}
