.card-footer {
  position: fixed;
  bottom: 0%;
  width: 100%;
  text-align: center;
  background-color: darkslategrey;
  border-top-width: 5px;
  border-color: green;
}
.Footer .container {
  background-image: none;
  background-color: rgb(46, 68, 68);
  background-size: cover;
  padding: 5px;
}

.Footer {
  width: 100%;
}
