.Portfolio {
  text-align: center;
}
.Portfolio h1 {
  font-family: "Luckiest Guy", cursive;
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 5px goldenrod, 0 0 5px gold, -1px 0 black,
    0 1px black, 1px 0 black, 0 -1px black;
}
.Portfolio #info-box {
  margin: auto;
  border-radius: 5px;
}
#card-info-box {
  margin: auto;
}
.ProjectCard {
  margin: auto;
  justify-content: center;
}
