.flip-card {
  background-color: transparent;
  width: 350px;
  height: 350px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: white;
  color: black;
}

.flip-card-back {
  background-color: white;
  color: black;
  transform: rotateY(180deg);
  overflow: scroll;
}
h6 {
  text-align: left;
}
.text {
  background-color: white;
  width: 350px;
  padding: 5px;
  margin: auto;
  /* margin-top: -8px; */
  margin-bottom: 10px;
  border-radius: 5px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
}
