.Resume {
  z-index: -2;
  margin-bottom: 50px;
}
.resume-btn {
  margin: 20px;
  background-color: rgb(1, 169, 1);
  border-color: black;
  border-radius: 50px;
  padding: 10px;
  margin-bottom: 25px;  
  /* text-shadow: 1px 1px 2px black, 0 0 25px goldenrod, 0 0 5px gold, -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  font-family: "Luckiest Guy", cursive;
}
.resume-header {
  text-align: center;
}
.resume-wordy-bits {
  font-size: smaller;
}
#PDFdiv{
  text-align: center;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5%;
  width: 80%;
  background-color: ivory;
  border-radius: 5px;

}
#resumePDF{
  width: 90%;
  height: 80vh;
}
 h1 {
  font-family: "Luckiest Guy", cursive;
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 5px goldenrod, 0 0 5px gold, -1px 0 black,
    0 1px black, 1px 0 black, 0 -1px black;
}